/*
 * @Author: liuhanchuan 
 * @Date: 2021-11-01 18:27:40 
 * @Last Modified by: liuhanchuan
 * @Last Modified time: 2022-11-03 15:44:57
 * @described: 通过灯塔APP扫码登录
 */

<template>
    <div class="qrcode_holder">
        <div class="qrcode_box">
            <h3>使用灯塔APP扫码登录</h3>
            <div class="qrcode" ref="qrCode"></div>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import request from './request'
export default {
    data: function() {
        return {
            connectUrl: 'https://sec.wedengta.com',
            session: '',
            hidden: true, // 控制显示扫码后的状态
        }
    },
    created() {
        this.getSession()
    },
    methods: {
        // 获取session
        getSession:function(){
            var that = this;
            request({
                url:  that.connectUrl+"/conn/",
                methods: 'get',
                params: {
                    type:'getQRCode'
                },
            }).then((data) => {
                that.getSessionAjaxSuccess(data);
            }).catch(() => {
                this.$message('服务器出错了，请稍后再试！')
            })
        },
        // 生成二维码
        getSessionAjaxSuccess(data) {
            this.session = data.Session;
            let url = data.ErrUrl+"?p=3&t=1&s=" + this.session;//p:0代表tv,1代表pc客户端,2代表mac,3代表tesla
            new QRCode(this.$refs.qrCode, {
                text: url,
                width: 204,
                height: 204,
                colorDark: "#FF7500",
                colorLight: "#fff",
            });
            let options = {
                session: this.session
            };
            this.getMessage(options);
        },
        // 获取当前扫码状态
        getMessage(paraOptions) {
            var that = this;
            request({
                url: that.connectUrl+"/conn/",
                methods: 'get',
                params: {
                    type:'connect',
                    sessionid:paraOptions.session
                },
            }).then((data) => {
                that.getMessageAjaxSuccess(data);
            }).catch(() => {
                that.getMessage(paraOptions);
            })
        },
        // 对扫码后状态进行处理
        getMessageAjaxSuccess(data) {
            let options = {
                session: this.session
            }
            let message = {}
            message.iLinkCode = JSON.parse(JSON.stringify(data.iLinkCode));
            /* 14：有用户扫码
            15：链路超时，需重新发connect请求
            0：用户扫码匹配成功，确认登录
            16:会话sessioin失效，这是页面自动刷新，重新获取新的session
            18:用户取消登录
            */
           if(message.iLinkCode == 0){
            //登录成功
                this.loginSuccess(data);
            }
            if(message.iLinkCode == 14) {
                this.hidden = false
                this.getMessage(options);
            }
            if (message.iLinkCode == 15)
            {
                this.getMessage(options);
            }
            if(message.iLinkCode == 16) {
                this.hidden = true
                this.$refs.qrCode.innerHTML = ''
                this.getSession(options);
            }
            if(message.iLinkCode == 18) {
                location.reload();
            }
        },
        // 登陆成功状态
        async loginSuccess(data) {
            //存储登录信息
            localStorage.setItem('loginInfo', JSON.stringify({desc:'登录信息',data: data.stLoginRsp}))
            const { sFaceUrl, sPhoneNum, sUserName, iAccountId } = data.stLoginRsp.stAccountInfo
            const loginData = await this.getToken({
                iAccountId: iAccountId,
                sFaceUrl: sFaceUrl,
                sUserName: sUserName,
                telephone: sPhoneNum
            })
            localStorage.setItem('userInfo', JSON.stringify({
                userName: sUserName || '',
                userPhoneNum: sPhoneNum || '',
                userImage: sFaceUrl || '',
                authorityVisible: loginData.whiteStatus ? true : false, // 控制7天是否显示
                whiteStatus: loginData.whiteStatus, // 白名单 0|白名单 1|游客
                remainTime: Math.ceil(loginData.remainTime / 3600 / 24), // 剩余天数
                remainVideoNumber: loginData.remainVideoNumber // 剩余上传次数
            }))
            // 通知Header组件获取登录信息
            this.$bus.$emit('login_Success')
            this.$router.push({ path: "/home" });
        },
        // 通过扫码获取到的userid得到token
        getToken(params) {
            return new Promise((resolve) => {
                this.API.login.getToken({
                    iAccountId: params.iAccountId,
                    sFaceUrl: params.sFaceUrl,
                    sUserName: params.sUserName,
                    telephone: params.telephone,
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        localStorage.setItem('Authorization', res.data.token)
                        resolve(res.data)
                    } else {
                        this.$message.error('获取token失败！')
                         resolve({})
                    }
                   
                }).catch(() => {
                    this.$message.error('获取token失败！')
                    resolve({})
                })
            }) 
        }
    }
}
</script>
<style lang="less" scoped>
.qrcode_holder {
    position: fixed;
    z-index: 9999;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    bottom: 0;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    display: -webkit-box;
    -webkit-box-pack:center;
    -webkit-box-align: center;
    -webkit-box-orient:vertical;
    .qrcode_box {
        width: 560px;
        height: 480px;
        border-radius: 32px;
        position: relative;
        background: #fff url('../../../assets/images/bg.svg') 0 0 no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        > h3 {
            color: #000000;
            font-weight: bold;
            font-size: 24px;
            margin: 48px 0;
        }
        .qrcode {
            margin-top: 18px;
        }
    }
}
</style>