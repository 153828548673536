import axios from "axios";
// import { Message } from "element-ui";

const onError = error => {
  if (error.response) {
    const status = error.response.status;
    const message = error.response.statusText;
    if (status === 500) {
      console.log(message)
      // Message.error({
      //   message: "服务器错误",
      //   description: message
      // });
    }
  }
  return Promise.reject(error);
};

const request = axios.create({
  baseURL: "",
  timeout: 100 * 1000,
  headers: {
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
    },
    post: {
      "Content-Type": "application/json;charset=utf-8"
    }
  },
  // 是否跨站点访问控制请求
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      if (headers["Content-Type"] === "multipart/form-data") {
        return data;
      } else {
        return JSON.stringify(data);
      }
    }
  ]
});

// 响应拦截器
request.interceptors.response.use(response => {
  const status = response.status;
  if (status != 200) {
    return Promise.reject(response);
  }
  return response.data;
}, onError);

export default request;
