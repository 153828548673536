<template>
    <div class="login_container">
        <AppLogin v-if="loginVisible" />
        <WeChatLogin v-else />
    </div>
</template>
<script>
import AppLogin from './AppLogin.vue'
import WeChatLogin from './WeChatLogin.vue'
export default {
    data: function() {
        return {
            loginVisible: true
        }
    },
    components: {
        AppLogin,
        WeChatLogin
    },
    created() {
        // 根据attachments中的配置文件区分当前登录方式
        if (LOGIN_METHOD === 'APP') {
            this.loginVisible = true
        } else {
            this.loginVisible = false
        }
    },
}
</script>
